import React from 'react'
import Wrapper from '../../components/wrapper'
import EmployerJoinHero from '../../components/pageComponents/employerJoinHero'
const EmployerSignUp = ({ location }) => {
  const Children = props => {
    return (
      <>
        <EmployerJoinHero />
      </>
    )
  }

  return (
    <Wrapper
      location={location}
      hideCTA={true}
      title="Employer Sign Up | MedReps"
      description="Explore MedReps' range of job poster plans, each tailored to meet different hiring needs and budgets."
      employerFacing={true}
    >
      <Children />
    </Wrapper>
  )
}
export default EmployerSignUp
